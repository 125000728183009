import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import StyledFlexBox from '../../styled/layouts/StyledFlexBox';
import bulletListIconGreen from '../../../images/bullet-list-icon-green.svg';

import style from '../../../constants/style';

const ITEM_SIZE_TABLET = 21;
const ITEM_SIZE_MOBILE = 19;
const GAP = 3;
const CURSOR_BULLET_WIDTH = '13px';
const CURSOR_BULLET_MARGIN = '1px';

const StyledCarousel = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
`;

const StyledCarouselWrapper = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
  left: 50%;
  width: ${(props) => `${props.nbSlides * ITEM_SIZE_MOBILE - GAP}rem`};
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.35, 1);

  transform: ${(props) => `translateX(calc(${-1 * (props.current) * ITEM_SIZE_MOBILE}rem - ${(ITEM_SIZE_MOBILE - GAP) / 2}rem))`};
  ${style.media.tablet`
    width: ${(props) => `${props.nbSlides * ITEM_SIZE_TABLET - GAP}rem`};
    transform: ${(props) => `translateX(calc(${-1 * (props.current) * ITEM_SIZE_TABLET}rem - ${(ITEM_SIZE_TABLET - GAP) / 2}rem))`};
  `}
`;

const StyledCarouselItem = styled.div`
  display: flex;
  flex-shrink: 0;

  width: ${ITEM_SIZE_MOBILE - GAP}rem;
  ${style.media.tablet`
    width: ${ITEM_SIZE_TABLET - GAP}rem;
  `}

  margin: 0 ${GAP / 2}rem;
  align-items: stretch;
  justify-content: stretch;
  opacity: 0.25;
  cursor: pointer;
  transition:
    opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.84),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.84);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${(props) => (props.current ? 'opacity: 1;' : null)}
  ${(props) => (props.after ? `
    &:hover {
      opacity: 0.5;
      transform: translateX(-2%);
    };
  ` : null)}
  ${(props) => (props.before ? `
    &:hover {
      opacity: 0.5;
      transform: translateX(2%);
    };
  ` : null)}
`;

const StyledCarouselControl = styled('div')`
  cursor: pointer;
  margin: 0 0.5rem;
  opacity: 0.25;

  border: 2px solid ${style.colours.primary};
  border-radius: 50%;
  height: 15px;
  width: 15px;

  ${({ current }) => (current ? `
    background-image: url(${bulletListIconGreen});
    background-position: ${CURSOR_BULLET_MARGIN};
    background-repeat: no-repeat;
    background-size: ${CURSOR_BULLET_WIDTH};
  ` : '')}

  ${(props) => (props.current ? 'opacity: 1;' : `
    &:hover {
      opacity: 0.5;
    };
  `)}
`;

const Carousel = (props) => {
  const {
    children,
  } = props;

  const [current, setCurrent] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (current < children.length - 1) setCurrent(current + 1);
    },
    onSwipedRight: () => {
      if (current > 0) setCurrent(current - 1);
    },
  });

  const handleSlideClick = (index) => useCallback(
    (e) => {
      e.preventDefault();
      if (current !== index) setCurrent(index);
    }, [current, setCurrent],
  );

  return (
    // Spreading recommended as described here https://www.npmjs.com/package/react-swipeable#hook-details
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledCarousel {...handlers}>
      <StyledCarouselWrapper current={current} nbSlides={children.length}>
        {children.map((child, index) => (
          <StyledCarouselItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            current={current === index}
            before={current > index}
            after={current < index}
            onClick={handleSlideClick(index)}
          >
            {child}
          </StyledCarouselItem>
        ))}
      </StyledCarouselWrapper>
      <StyledFlexBox justifyContent="center" marginTop="regular">
        {children.map((_slide, index) => (
          <StyledCarouselControl
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            current={current === index}
            onClick={handleSlideClick(index)}
          />
        ))}
      </StyledFlexBox>
    </StyledCarousel>
  );
};

Carousel.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.node,
  ).isRequired,
};

export default Carousel;
