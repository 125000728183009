import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { rgba } from 'polished';

import StyledFlexBox from '../../styled/layouts/StyledFlexBox';
import StyledImg from '../../styled/elements/StyledImg';
import StyledParagraph from '../../styled/elements/StyledParagraph';

import style from '../../../constants/style';

const StyledWrapper = styled.div`
  padding: ${style.spaces.regular} ${style.spaces.small};
  text-align: center;
  background-color: ${style.colours.light};
  border-radius: 1.5rem;

  box-shadow: 0px 5px 10px ${rgba(style.colours.dark, 0.25)};

  ${StyledParagraph} {
    margin: 0;
  }
`;

const Card = (props) => {
  const {
    headerImg,
    headerAlt,
    description,
    quote,
  } = props;

  return (
    <StyledWrapper>
      <StyledFlexBox
        flexDirection="column"
        alignItems="center"
        alignSelf="stretch"
        height="100%"
      >
        <StyledFlexBox flexShrink="0">
          <StyledImg src={headerImg} alt={headerAlt} width="100%" />
        </StyledFlexBox>
        <StyledFlexBox
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <StyledFlexBox marginTop="tiny">
            <StyledParagraph
              textAlign="left"
              fontWeight="light"
              fontSize="smaller"
            >
              {description}
            </StyledParagraph>
          </StyledFlexBox>
          <StyledFlexBox marginTop="regular">
            <StyledParagraph
              textAlign="left"
              fontWeight="lighter"
              fontSize="tiny"
            >
              {quote}
            </StyledParagraph>
          </StyledFlexBox>
        </StyledFlexBox>
      </StyledFlexBox>
    </StyledWrapper>
  );
};

Card.propTypes = {
  headerImg: PropTypes.string.isRequired,
  headerAlt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
};

export default Card;
