import styled from 'styled-components';

import style from '../../../constants/style';

const getColor = ({ colour }) => style.colours[colour];

const CIRCLE_SIZE_MOBILE = '2rem';
const CIRCLE_SIZE_TABLET = '3rem';
const CIRCLE_BORDER = '1px';

const StyledNumberedTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;

  width: 100%;

  h2 {
    margin: 0;
  }

  div {
    height: ${CIRCLE_SIZE_MOBILE};
    width: ${CIRCLE_SIZE_MOBILE};
    border-radius: 50%;
    margin-right: ${style.spaces.small};

    border: ${CIRCLE_BORDER} ${getColor} solid;

    font-size: ${style.fontSizes.smaller};
    color: ${getColor};
    font-weight: ${style.fontWeights.bold};

    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${style.media.tablet`
    div {
      height: ${CIRCLE_SIZE_TABLET};
      width: ${CIRCLE_SIZE_TABLET};
    }
  `}
`;

export default StyledNumberedTitle;
