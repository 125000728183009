import React from 'react';
import PropTypes from 'prop-types';

import StyledInput from '../../styled/elements/StyledInput';
import StyledLabel from '../../styled/elements/StyledLabel';
import StyledParagraph from '../../styled/elements/StyledParagraph';

import StyledFlexBox from '../../styled/layouts/StyledFlexBox';

const Input = ({
  id,
  label,
  labelColour,
  type,
  value,
  onChange,
  hasError,
}) => (
  <StyledFlexBox flexDirection="column">
    <StyledLabel htmlFor={id} textColour={labelColour}>{label}</StyledLabel>
    <StyledInput id={id} hasError={hasError} type={type} value={value} onChange={onChange} />
    <StyledFlexBox justifyContent="flex-end" textColour="lightError">
      <StyledParagraph>{hasError ? 'Felaktigt format' : '\u00A0'}</StyledParagraph>
    </StyledFlexBox>
  </StyledFlexBox>
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelColour: PropTypes.string,
  type: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
};

Input.defaultProps = {
  labelColour: null,
  hasError: false,
};

export default Input;
