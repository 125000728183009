import styled from 'styled-components';

import style from '../../../constants/style';

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  & > * {
    max-width: 15rem;
    width: 100%;
  }

  ${style.media.tablet`
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
      margin-right: 1rem;
    }
  `}
`;

export default StyledForm;
