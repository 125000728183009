import React from 'react';
import PropTypes from 'prop-types';

import StyledFlexBox from '../../styled/layouts/StyledFlexBox';
import StyledH3 from '../../styled/elements/StyledH3';
import StyledImg from '../../styled/elements/StyledImg';

import decreaseTurnover from '../../../images/decrease-turnover.svg';
import increaseProductivity from '../../../images/increase-productivity.svg';
import decreaseSickdays from '../../../images/decrease-sickdays.svg';

const BenefitCard = ({ title, icon }) => (
  <StyledFlexBox
    flexDirection="column"
    alignItems="center"
    margin="small"
    width="15rem"
  >
    <StyledH3
      textColour="primary"
      textAlign="center"
      fontWeight="bolder"
      marginBottom="small"
    >
      {title}
    </StyledH3>
    <StyledFlexBox marginTop="tiny">
      <StyledImg src={icon} alt={title} />
    </StyledFlexBox>
  </StyledFlexBox>
);
BenefitCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

const cards = [{
  title: 'Minskad personalomsättning',
  icon: decreaseTurnover,
}, {
  title: 'Ökad produktivitet',
  icon: increaseProductivity,
}, {
  title: 'Färre sjukdagar',
  icon: decreaseSickdays,
}];

const BenefitsCards = () => (
  <StyledFlexBox
    justifyContent="center"
    alignItems="flex-end"
    flexWrap="wrap"
  >
    {cards.map((item) => (
      <BenefitCard
        key={item.title}
        title={item.title}
        icon={item.icon}
      />
    ))}
  </StyledFlexBox>
);

export default BenefitsCards;
