import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { toUnicode } from 'punycode';
import metadata from './metadata.custom.json';

const isDefined = (value) => value !== undefined && value !== null && value !== '';
const optional = (validation) => (value, ...rest) => (isDefined(value)
  ? validation(value, ...rest)
  : true
);
const required = (validation) => (value, ...rest) => (isDefined(value)
  ? validation(value, ...rest)
  : false
);

const isString = (value) => typeof value === 'string';
const isNumber = (value) => !Number.isNaN(parseFloat(value)) && Number.isFinite(+value);
const isPositiveNumber = (value) => isNumber(value) && parseFloat(value) >= 0;

export default {
  email: (value) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(toUnicode(value)),
  phone: (value) => {
    if (!value) return false;
    const parsed = parsePhoneNumberFromString(value, 'SE', metadata);
    return !!parsed && parsed.isValid() && parsed.country === 'SE';
  },
  generics: {
    isDefined,
    isNumber,
    isPositiveNumber,
    isString,
    optional,
    required,
  },
};
