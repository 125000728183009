import React from 'react';
import PropTypes from 'prop-types';

import StyledNumberedTitle from '../../styled/elements/StyledNumberedTitle';
import StyledH2 from '../../styled/elements/StyledH2';

const NumberedTitle = ({ num, children, colour }) => (
  <StyledNumberedTitle colour={colour}>
    <div>{num}</div>
    <StyledH2 textColour={colour}>{children}</StyledH2>
  </StyledNumberedTitle>
);

NumberedTitle.propTypes = {
  children: PropTypes.node.isRequired,
  num: PropTypes.string.isRequired,
  colour: PropTypes.string,
};

NumberedTitle.defaultProps = {
  colour: 'dark',
};

export default NumberedTitle;
