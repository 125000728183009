import React, { useState, useCallback } from 'react';

import Input from '../elements/Input';

import StyledFlexBox from '../../styled/layouts/StyledFlexBox';
import StyledButton from '../../styled/elements/StyledButton';
import StyledForm from '../../styled/layouts/StyledForm';

import useInput from '../../../hooks/useInput';

import useModalContext from '../../modals/useModalContext';

import sendPostRequest from '../../../utils/sendPostRequest';
import validators from '../../../utils/validators';

const { generics, email: emailValidator, phone: phoneValidator } = validators;

const ContactForm = () => {
  const [companyName, companyNameOnChange, companyNameReset, companyError, companyValidate] = useInput('', generics.required(generics.isString));
  const [contactPerson, contactPersonOnChange, contactPersonReset, contactError, contactValidate] = useInput('', generics.required(generics.isString));
  const [phone, phoneOnChange, phoneReset, phoneError, phoneValidate] = useInput('', phoneValidator);
  const [email, emailOnChange, emailReset, emailError, emailValidate] = useInput('', emailValidator);
  const [employees, employeesOnChange, employeesReset, employeesError, employeesValidate] = useInput('', generics.isPositiveNumber);

  const [loading, setLoading] = useState(false);

  const { templates, showModal } = useModalContext();

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const fieldsValidity = [
        companyValidate(),
        contactValidate(),
        phoneValidate(),
        emailValidate(),
        employeesValidate(),
      ];
      if (fieldsValidity.some((valid) => !valid)) return false;

      setLoading(true);
      showModal(templates.LOADING);
      const body = {
        companyName,
        contactPerson,
        phone,
        email,
        employees,
      };
      try {
        await sendPostRequest('iconomic', body);
        setLoading(false);
        showModal(templates.THANK_YOU);

        companyNameReset();
        contactPersonReset();
        phoneReset();
        emailReset();
        employeesReset();
        return true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setLoading(false);
        showModal(templates.ERROR);
        return false;
      }
    },
    [
      companyName,
      contactPerson,
      phone,
      email,
      employees,
      companyValidate,
      contactValidate,
      phoneValidate,
      emailValidate,
      employeesValidate,
      companyNameReset,
      contactPersonReset,
      phoneReset,
      emailReset,
      employeesReset,
      showModal,
      templates,
    ],
  );
  return (
    <StyledForm>
      <Input
        id="companyName"
        label="Företagsnamn"
        labelColour="light"
        type="text"
        value={companyName}
        onChange={companyNameOnChange}
        hasError={companyError}
      />
      <Input
        id="contactPerson"
        label="Kontaktperson"
        labelColour="light"
        type="text"
        value={contactPerson}
        onChange={contactPersonOnChange}
        hasError={contactError}
      />
      <Input
        id="phone"
        label="Telefonnummer"
        labelColour="light"
        type="tel"
        value={phone}
        onChange={phoneOnChange}
        hasError={phoneError}
      />
      <Input
        id="email"
        label="E-post"
        labelColour="light"
        type="email"
        value={email}
        onChange={emailOnChange}
        hasError={emailError}
      />
      <Input
        id="employees"
        label="Antal medarbetare"
        labelColour="light"
        type="tel"
        value={employees}
        onChange={employeesOnChange}
        hasError={employeesError}
      />
      <StyledFlexBox marginTop="tiny" justifyContent="center">
        <StyledButton
          onClick={onSubmit}
          disabled={loading}
          primary="secondary"
          secondary="primary"
        >
          Skicka in
        </StyledButton>
      </StyledFlexBox>
    </StyledForm>
  );
};

export default ContactForm;
