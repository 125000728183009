import { useState, useCallback } from 'react';

const useInput = (initialValue, validator) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);

  const onChange = useCallback((event) => {
    setError(false);
    if (validator && !validator(event.target.value)) setError(true);
    setValue(event.target.value);
  }, [validator]);

  const reset = useCallback(() => {
    setValue(initialValue);
    setError(false);
  }, [initialValue]);

  const validate = useCallback(() => {
    const isValid = !validator || validator(value);
    setError(!isValid);
    return isValid;
  }, [validator, value]);

  return [value, onChange, reset, error, validate];
};

export default useInput;
