import React from 'react';

import SmoothScrollAnchor from '../elements/SmoothScrollAnchor';

import StyledHero, { StyledHeroContent } from '../../styled/layouts/StyledHero';
import StyledH1 from '../../styled/elements/StyledH1';
import StyledImg from '../../styled/elements/StyledImg';
import StyledParagraph from '../../styled/elements/StyledParagraph';
import StyledButton from '../../styled/elements/StyledButton';

import heroIllustration from '../../../images/hero-illustration.svg';

const Hero = () => (
  <StyledHero>
    <StyledHeroContent>
      <StyledH1>Ökad produktivitet för företag genom ekonomisk trygghet</StyledH1>
      <StyledImg src={heroIllustration} alt="Ekonomisk trygghet" />
      <StyledParagraph>Vi hjälper arbetsgivare att skapa balans och trygghet i medarbetarnas ekonomi vilket ger nöjda anställda som presterar bättre på arbetsplatsen.</StyledParagraph>
      <StyledButton as={SmoothScrollAnchor} href="#contact">Kontakta oss</StyledButton>
    </StyledHeroContent>
  </StyledHero>
);

export default Hero;
