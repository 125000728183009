import React from 'react';

import Layout from '../components/logic/layouts/Layout';
import SEO from '../components/seo';

import Hero from '../components/logic/layouts/Hero';
import Section from '../components/logic/layouts/Section';
import ContactForm from '../components/logic/layouts/ContactForm';

import NumberedTitle from '../components/logic/elements/NumberedTitle';
import SmoothScrollAnchor from '../components/logic/elements/SmoothScrollAnchor';
import AsideLayout, { StyledAside } from '../components/logic/layouts/AsideLayout';
import BenefitsCards from '../components/logic/layouts/BenefitsCards';

import StyledImg from '../components/styled/elements/StyledImg';
import StyledH2 from '../components/styled/elements/StyledH2';
import StyledButton from '../components/styled/elements/StyledButton';
import StyledParagraph from '../components/styled/elements/StyledParagraph';
import StyledFlexBox from '../components/styled/layouts/StyledFlexBox';

import Carousel from '../components/logic/layouts/Carousel';
import Card from '../components/logic/layouts/Card';

import employerLoan from '../images/employer-loan.png';
import consulting from '../images/consulting.png';
import budget from '../images/budget.png';
import insurance from '../images/insurance.png';
import contact from '../images/contact.png';
import soderbergPartner from '../images/soderberg-partner.png';
import framtidsmediaPartner from '../images/framtidsmedia-partner.png';
import stressFinantialSituation from '../images/stress-financial-situation.svg';
import workPerformance from '../images/work-performance.svg';
import monthlyBillsProblem from '../images/monthly-bills-problem.svg';
import professionalHelpAccess from '../images/professional-help-access.svg';

const IMAGE_MAX_WIDTH = '30rem';

const IndexPage = () => (
  <Layout>
    <SEO title="Landing" />
    <Hero />
    <Section background="tertiary" marginTop="none" marginBottom="none">
      <AsideLayout>
        <NumberedTitle num="1" colour="light">Medarbetarlån</NumberedTitle>
        <StyledAside>
          <StyledImg src={employerLoan} width="90%" maxWidth={IMAGE_MAX_WIDTH} alt="" />
        </StyledAside>
        <StyledParagraph textColour="light" fontWeight="lighter" maxWidth={IMAGE_MAX_WIDTH}>Medarbetarlånet är ett fördelaktigt lån till förmånlig ränta för medarbetarna. Tack vare att månadskostnaden för lånet dras som ett nettoavdrag på lönen har Iconomic möjlighet att erbjuda en mer attraktiv ränta till medarbetarna. Processen är snabb, smidig och löneavdraget är enkelt att implementera.</StyledParagraph>
        <StyledFlexBox marginTop="big">
          <StyledButton as={SmoothScrollAnchor} primary="secondary" secondary="primary" href="#contact">Kontakta oss</StyledButton>
        </StyledFlexBox>
      </AsideLayout>
    </Section>
    <Section marginTop="none" marginBottom="none">
      <StyledH2 textColour="primary" textAlign="center" fontWeight="bolder">Konkurrenskraftiga fördelar</StyledH2>
      <BenefitsCards />
      <StyledFlexBox marginTop="small" maxWidth="45rem" alignSelf="center">
        <StyledParagraph textAlign="center">Genom att främja ekonomisk balans och trygghet hos medarbetarna får arbetsgivaren en ökad prestation på arbetsplatsen och nöjda medarbetare som stannar längre i företaget.</StyledParagraph>
      </StyledFlexBox>
    </Section>
    <Section background="secondary" marginTop="none" marginBottom="none">
      <AsideLayout reverse>
        <NumberedTitle num="2" colour="primary">Rådgivning</NumberedTitle>
        <StyledAside>
          <StyledImg src={consulting} width="90%" maxWidth={IMAGE_MAX_WIDTH} alt="" />
        </StyledAside>
        <StyledParagraph fontWeight="lighter" maxWidth={IMAGE_MAX_WIDTH}>Genom Medarbetarlånet får medarbetarna tillgång till ekonomisk rådgivning där vi utgår från medarbetarnas behov och ekonomiska situation. Utifrån det tar vi fram förslag på åtgärder och förbättringsmöjligheter i syfte att uppnå balans och trygghet i medarbetarnas ekonomi, vilket bidrar till fler nöjda medarbetare på företaget.</StyledParagraph>
        <StyledFlexBox marginTop="big">
          <StyledButton as={SmoothScrollAnchor} href="#contact">Kontakta oss</StyledButton>
        </StyledFlexBox>
      </AsideLayout>
    </Section>
    <Section background="lightGrey" marginTop="none" marginBottom="none">
      <StyledFlexBox marginBottom="huge" justifyContent="center">
        <StyledH2 textColour="primary" textAlign="center" fontWeight="bolder">Ekonomisk ohälsa ökar bland medarbetarna</StyledH2>
      </StyledFlexBox>
      <Carousel>
        <Card
          headerImg={stressFinantialSituation}
          headerAlt="56%"
          description="Av medarbetarna är stressade över sin ekonomiska situation."
          quote="Bank of America. 2017. Workplace&nbsp;Benefits&nbsp;Report."
        />
        <Card
          headerImg={workPerformance}
          headerAlt="53%"
          description="Av medarbetarna som är stressade över sin ekonomi uppger att denna stress påverkar deras förmåga att prestera på arbetet."
          quote="Bank of America. 2017. Workplace&nbsp;Benefits&nbsp;Report."
        />
        <Card
          headerImg={monthlyBillsProblem}
          headerAlt="67%"
          description="Av medarbetarna som är stressade över sin ekonomi har svårt att betala sina räkningar varje månad."
          quote="Pwc. 2017. Social report: Financial stress and the bottom line."
        />
        <Card
          headerImg={professionalHelpAccess}
          headerAlt="40%"
          description="Av medarbetarna skulle vilja att deras arbetsgivare gav dem tillgång till professionell hjälp för att sätta upp en ekonomisk strategi."
          quote="Bank of America. 2017. Workplace&nbsp;Benefits&nbsp;Report."
        />
      </Carousel>
    </Section>
    <Section background="tertiary" marginTop="none" marginBottom="none">
      <AsideLayout>
        <NumberedTitle num="3" colour="light">Budgetverktyg</NumberedTitle>
        <StyledAside>
          <StyledImg src={budget} width="90%" maxWidth={IMAGE_MAX_WIDTH} alt="" />
        </StyledAside>
        <StyledParagraph textColour="light" fontWeight="lighter" maxWidth={IMAGE_MAX_WIDTH}>Iconomics Budgetverktyg är en del av Medarbetarlånet och ger en enkel översikt av medarbetarnas privatekonomi. Verktyget ger medarbetarna värdefulla insikter om hur de på egen hand kan agera för att förbättra sin ekonomiska situation.</StyledParagraph>
        <StyledParagraph textColour="light" fontWeight="lighter" maxWidth={IMAGE_MAX_WIDTH}>Baserat på sambandet mellan inkomster och utgifter får medarbetaren även möjlighet att veta hur sin ekonomiska situation ser ut i förhållande till Konsumentverkets kostnadsberäkning.</StyledParagraph>
        <StyledFlexBox marginTop="big">
          <StyledButton as={SmoothScrollAnchor} href="#contact" primary="secondary" secondary="primary">Kontakta oss</StyledButton>
        </StyledFlexBox>
      </AsideLayout>
    </Section>
    <Section marginTop="none" marginBottom="none">
      <StyledH2 textAlign="center" fontWeight="bolder">Några av våra samarbetspartners</StyledH2>
      <StyledFlexBox alignItems="center" justifyContent="center" flexWrap="wrap" flexGap="regular">
        <StyledImg src={framtidsmediaPartner} width="100%" maxWidth="5rem" maxHeight="5rem" alt="Framtidsmedia" />
        <StyledImg src={soderbergPartner} width="100%" maxWidth="24rem" maxHeight="5rem" alt="Söderberg and partners benefits" />
      </StyledFlexBox>
    </Section>
    <Section background="secondary" marginTop="none" marginBottom="none">
      <AsideLayout reverse>
        <NumberedTitle num="4" colour="primary">Försäkringar</NumberedTitle>
        <StyledAside>
          <StyledImg src={insurance} width="90%" maxWidth={IMAGE_MAX_WIDTH} alt="" />
        </StyledAside>
        <StyledParagraph fontWeight="lighter" maxWidth={IMAGE_MAX_WIDTH}>Iconomics försäkringar kan skapa ekonomisk trygghet hos medarbetarna för de situationer i livet som är svåra att förutse.</StyledParagraph>
        <StyledParagraph fontWeight="lighter" maxWidth={IMAGE_MAX_WIDTH}>Skulle medarbetarna bli sjukskrivna eller förlora jobbet kan Iconomic finnas där för att täcka lånets månadsbetalningar genom Låneskydd eller fasta levnadskostnader via Trygghetsförsäkring.</StyledParagraph>
        <StyledFlexBox marginTop="big">
          <StyledButton as={SmoothScrollAnchor} href="#contact">Kontakta oss</StyledButton>
        </StyledFlexBox>
      </AsideLayout>
    </Section>
    <Section id="contact" background="tertiary" marginTop="none" marginBottom="none">
      <AsideLayout reverse>
        <StyledH2 textColour="light" fontWeight="bold" textAlign="center">Kontakta Iconomic</StyledH2>
        <StyledAside>
          <StyledImg src={contact} width="90%" maxWidth={IMAGE_MAX_WIDTH} alt="" />
        </StyledAside>
        <ContactForm />
      </AsideLayout>
    </Section>
  </Layout>
);

export default IndexPage;
