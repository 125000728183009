import styled from 'styled-components';

import style from '../../../constants/style';

import redCross from '../../../images/red-cross.svg';

const PADDING_VALIDATION_ICON = style.spaces.big;
const PADDING_TINY = style.spaces.tiny;
const PADDING_SMALL = style.spaces.small;
const BORDER = '2px';

const WIDTH = `calc(100% - (${PADDING_VALIDATION_ICON} + ${PADDING_SMALL}) - (${BORDER} * 2))`;

const StyledInput = styled.input`
  width: ${WIDTH};
  padding: ${PADDING_TINY} ${PADDING_VALIDATION_ICON} ${PADDING_TINY} ${PADDING_SMALL};

  font-size: ${style.fontSizes.smaller};
  color: ${style.colours.dark};
  background-color: ${style.colours.light};

  border: ${BORDER} solid ${({ hasError }) => (hasError ? style.colours.error : 'transparent')};
  outline: none;
  appearance: none;

  border-radius: 5px;

  ${(props) => (props.hasError ? `background: white url(${redCross}) no-repeat right 0.75rem center;` : null)}

  &[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    display: none;
  }
`;

export default StyledInput;
